import logo from './logo.svg';
import styled from "styled-components";
import { Grid } from '@mui/material';
import './App.css';
import vid from './video.mp4';
import dexscreener from './dexscreener.png';
import x from './x.png';
import telegram from './telegram.png';
import game from './game.png';


const Video = styled.video`
  width: 100%;
  max-height: 100vh;
  // height: auto;
  /* Add any additional styles here */
`;

const StyledIcon = styled.img`
  margin-top: 20px;
  height: 40px;
  cursor: pointer;
  transition: transform 0.3s ease; /* Add transition for smooth animation */

  /* Define hover effect */
  &:hover {
    transform: scale(1.5); /* Increase size by 50% */
  }
`

const StyledMenu = styled.div`
  position: absolute;
  bottom: 20px;
`


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Video autoPlay loop muted>
          <source src={vid} type="video/mp4" />
          Your browser does not support the video tag.
        </Video>

        <StyledMenu>
          <Grid container direction="row" spacing={5} justifyContent={"center"} alignItems={"center"}>
          <Grid item>
              <StyledIcon src={game} onClick={() => window.open("https://game.com/memeboard", "_blank")}></StyledIcon>
            </Grid>
            <Grid item>
              <StyledIcon src={dexscreener} onClick={() => window.open("https://dexscreener.com/solana/d9vwpph9x73w6crphpxpu4nivceevfpdzwsw19nnphne", "_blank")}></StyledIcon>
            </Grid>

            <Grid item>
              <StyledIcon src={x} onClick={() => window.open("https://x.com/darklordonsol", "_blank")}></StyledIcon>
            </Grid>
            <Grid item>
              <StyledIcon src={telegram} onClick={() => window.open("https://x.com/darklordonsol", "_blank")}></StyledIcon>
            </Grid>
          </Grid>

        </StyledMenu>
      </header>
    </div>
  );
}

export default App;
